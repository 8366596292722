export const Constants = {
  DATA_DEADLINE_NOT_URGENT_DAYS_GAP: 5,
  DATA_DEADLINE_URGENT_DAYS_GAP: 2,
  // Status
  STATUS_IN_PROGRESS_ID: 1,
  STATUS_IN_PROGRESS: 'In progress',
  STATUS_QUALITY_CHECK_ID: 2,
  STATUS_QUALITY_CHECK: 'Quality check',
  STATUS_REJECTED_ID: 3,
  STATUS_REJECTED: 'Rejected',
  STATUS_APPROVED_ID: 4,
  STATUS_APPROVED: 'Approved',
  STATUS_UPLOADED_ID: 5,
  STATUS_UPLOADED: 'Uploaded',
  STATUS_PENDING_REVIEW_ID: 6,
  STATUS_PENDING_REVIEW: 'Pending review',
  STATUS_DELETED_ID: 7,
  STATUS_DELETED: 'Deleted',
  // Accepted File extension list
  ACCEPTED_FILE_EXTENSIONS: ['.png', '.jpg', '.jpeg', '.pdf'],
  ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE: ['.png', '.jpg', '.jpeg', '.pdf', '.mp4'],
};
